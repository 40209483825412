<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'
import { getArApApi } from '@/api/cash'
import {getCashReportApi} from  '@/api/cash'


import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";

import EftPaymentDialog from './components/eft_payment_dialog.vue'
import PrintChequeDialog from './components/print_cheque_dialog.vue'
import TransferDialog  from './components/make_transfer_dialog.vue'
import PaymentEditDialog from "./components/edit_transaction_payment_dialog.vue";

import { financial } from '@/api/misc'
import moment from 'moment'


export default {
  page: {
    title: "PM Trust",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Account Receivable",
      items: [
        {
          text: "PM Trust Mgt",
          href: "/",
        },
      ],
      totalAmount : 0,
      totalRows: 0,
      currentPage: 1,
      perPage: 50,
      query_page_size : 50,
      totalPage : 1,
      queryed_page: [],
      filter: {SearchStr : ''},
      filterOn: ['type'],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "G",
          label : '',
          sortable: false,
           tdClass: 'align-middle'
        },

        {
          key: "ID",
          label : '',
          sortable: false,
           tdClass: 'align-middle'
        },

        {
          key: "Payee",
          sortable: true,
           tdClass: 'align-middle'
        },

        {
          key: "Date",
          sortable: true,
           tdClass: 'align-middle'
        },
        {
          key: "Amount",
          sortable: false,
          tdClass: 'align-middle'
        },

        {
          key: "PayType",
          sortable: false,
           tdClass: 'align-middle'
        },
        
        {
          key: "Reference",
          sortable: false,
           tdClass: 'align-middle'
        },
        

        {
          key: "Status",
          sortable: false,
           tdClass: 'align-middle'
        },

        {
          key: "Action",
          sortable: false,
           tdClass: 'align-middle'
        },

        
      ],
      rangeOptions: {
        mode: "range",
      },
     

      pay_type : '',
      query_type : 'PM-LANDLORD',
      query_agent_id: '',
      query_deal_id: '',
      queryRange : '',
      query_status : 'PENDING',
      selected_transactions: [],
      trans_pay_type : '',
      filter_transaction_list: [],

      rev_trans: {},
      current_transaction : {},

      current_progress : 10,
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    EftPaymentDialog,
    PrintChequeDialog,
    TransferDialog,
    PaymentEditDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

    append_btn_disabled() {
      return this.queryed_page.length >= this.totalPage
    },

    cheque_btn_disabled_flag() {
      let progress = this.selected_transactions.filter(e => e.status != 'PENDING')
      return progress.length > 0 ? true : false
     
    },

    eft_btn_disabled_flag() {
      if (this.selected_transactions.length <= 0 || this.trans_pay_type !='EFT') {
        return true
      } else {
        return false
      }
    },

    transfer_btn_disabled_flag() {
      if (this.selected_transactions.length <= 0 || this.trans_pay_type !='TRANSFER') {
        return true
      } else {
        return false
      }
    },
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {

    new Choices('#status_choice', {
    });

    new Choices('#type_choice')

  },
  methods: {

    queryList(page_no) {

      let data = {
        page: {
          page_no   : page_no,
          page_size: this.query_page_size
        },
        query_type : this.query_type,
        pay_type   : this.pay_type,
        status     : this.query_status,
      }

      this.totalAmount = 0
      getCashApi().ap_list(data).then((res) => {
        if (res.errCode == 0) {
          this.list = []
          this.list.push(...res.data)


          this.list.map(e => e.mdate = moment(e.due_date))



          this.totalRows = res.page.total;
          this.totalPage = Math.ceil(res.page.total/res.page.page_size)
          this.queryed_page.push(res.page.page_no)
          let new_amount = res.data.reduce((a, c)=> a+ Number(c.amount), 0)
          this.totalAmount += new_amount
        }

      })


    },

    pageChange(p) {
      this.currentPage = p
      this.queryList(this.currentPage)
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
        this.filter_transaction_list = filteredItems
        this.totalAmount = this.filter_transaction_list.reduce((a,c) => a + Number(c.amount), 0)
    },



    onSearch() {
      this.queryList(this.currentPage)
    },

    onShowEFT(evt) {
      let searchedStr = this.filter.SearchStr
      if (evt.target.checked == true) {

        this.filter = {PayType : 'EFT', SearchStr:  searchedStr}
      } else {
        this.filter = {SearchStr:  searchedStr}
      }
    },

    onLoadAll(evt) {

      if (evt.target.checked == true) {
        this.load_all_pending_data()
      } else {
        this.perPage     = 50
        this.currentPage = 1
        this.queryList(this.currentPage)

      }
    },

    filterTable(row, filter) {
      if (filter.PayType && filter.SearchStr) {
      
        return row.type == filter.PayType  && (row.cash_record.pm.property_id.startsWith(filter.SearchStr) || row.payee?.startsWith(filter.SearchStr))
      } else if (filter.PayType ) {
        return row.type == filter.PayType 
      }  else if (filter.SearchStr) {
       
        return row.cash_record.pm.property_id.startsWith(filter.SearchStr) || row.payee?.startsWith(filter.SearchStr)
      } else {
        return true
      }
    },


    onSelectAll(evt) {
      if (evt.target.checked == true) {
        this.selected_transactions = this.filter_transaction_list
      } else {
        this.selected_transactions = []
      }

      this.filter_transaction_list.map(t => t.checked = evt.target.checked)

      let cheque_cflag  = this.selected_transactions.some(t =>t.type =='CHEQUE')
      let eft_cflag  = this.selected_transactions.some(t =>t.type =='EFT')
      let transfer_cflag  = this.selected_transactions.some(t =>t.type =='TRANSFER')

      if ((cheque_cflag && eft_cflag && transfer_cflag) || (eft_cflag && transfer_cflag) || (cheque_cflag && eft_cflag) || (cheque_cflag && transfer_cflag)) {
        this.trans_pay_type = 'MISC'
      } else if (eft_cflag) {
        this.trans_pay_type = 'EFT'
      } else if (cheque_cflag) {
        this.trans_pay_type = 'CHEQUE'
      } else if (transfer_cflag) {
        this.trans_pay_type = 'TRANSFER'
      }
    },


    onSelectedTransaction(evt, t)  {
      if (evt.target.checked == true) {
        let d = this.selected_transactions.find((e)=> e.id == t.id)
        if (!d) {
          this.selected_transactions.push(t)
        }

      } else {
        this.selected_transactions = this.selected_transactions.filter((e)=> e.id != t.id)
      }

      let cheque_cflag  = this.selected_transactions.some(t =>t.type =='CHEQUE')
      let eft_cflag  = this.selected_transactions.some(t =>t.type =='EFT')
      let transfer_cflag  = this.selected_transactions.some(t =>t.type =='TRANSFER')

      if ((cheque_cflag && eft_cflag && transfer_cflag) || (eft_cflag && transfer_cflag) || (cheque_cflag && eft_cflag) || (cheque_cflag && transfer_cflag)) {
        this.trans_pay_type = 'MISC'
      } else if (eft_cflag) {
        this.trans_pay_type = 'EFT'
      } else if (cheque_cflag) {
        this.trans_pay_type = 'CHEQUE'
      } else if (transfer_cflag) {
        this.trans_pay_type = 'TRANSFER'
      }
    },

    onEftSetup() {
      this.selected_transactions.map(e => e.status ='PROGRESS')
      this.$bvModal.hide('modal-pm-eft-payment-dialog')
      this.$alertify.message("Setup EFT Success  ");

      this.queryList(this.currentPage);
      this.selected_transactions = []
    },

    onPrinted() {
      this.list = this.list.filter(e => {
        return  !this.selected_transactions.find(ee => e.id == ee.id) 
      })
      
      this.selected_transactions = []
      
      this.$bvModal.hide('modal-cheque-payment-dialog')
    },

    onReQuery() {
      this.currentPage = 1
      this.queryed_page = []
      this.totalPage = 1
      this.queryList(this.currentPage)
    },

    onAppendPage() {
      let next_page_no = this.queryed_page.length + 1
      
      let data = {
        page: {
          page_no   : next_page_no,
          page_size : this.query_page_size
        },
        query_type : this.query_type,
        pay_type   : this.pay_type,
        status     : this.query_status,
      }

      getCashApi().ap_list(data).then((res) => {
       
        if (res.errCode == 0) {
          this.list.push(...res.data)
          this.list.map(e => e.mdate = moment(e.due_date))

          this.perPage  += res.data.length
          this.totalRows = res.page.total;
          this.totalPage = Math.ceil(res.page.total/res.page.page_size)
          this.queryed_page.push(res.page.page_no)
          this.$alertify.error("New Record Appended  Page: " + res.page.page_no);
          let new_amount = res.data.reduce((a, c)=> c + Number(c.amount), 0)
          this.totalAmount += new_amount
        }
      })

    },

 
    onCancelPayment(item, payment) {
     
      getArApApi().cancel_transaction({trans_id : payment.id}).then(res => {
        if (res.errCode == 0) {
          this.list.splice(item.index, 1)
        } else {
          this.$alertify.error("Cancel Payment Failed  ret:" + res.errCode);
        }
      })
    },

    onReversePayment(item, t) {
      this.rev_trans = t
      this.rev_trans.index = item.index
      this.$bvModal.show('reverse_transaction_dialog')
    },

    editPaymentType(item, t) {
      console.log(item)
      this.current_transaction = t
      this.$bvModal.show('modal-payment-eidt-dialog')
     
    },

    onPaymentEdit() {

    },


    onConfirmReverseTrans() {
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Reverse this Transaction?",
        () => {
          getArApApi().reverse_transaction({trans_id : this.rev_trans.id, post_date : this.rev_trans.post_date, note : this.rev_trans.note}).then(res => {
            if (res.errCode == 0) {
              this.list.splice(this.rev_trans.index)
              this.$alertify.message("Reversed ");
              this.$bvModal.hide('reverse_transaction_dialog')
            } else {
              this.$alertify.error("Failed to Cancel ret:" + res.errCode);
            }
          })
        })
    },

    onTransfered() {
      this.$bvModal.hide('modal-pm-transfer-payment-dialog')

      this.list = this.list.filter(e => {
        return  !this.selected_transactions.find(ee => e.id == ee.id) 
      })

      this.selected_transactions = []
    },

    get_payee_val(item) {
      if (item.type == 'TRANSFER') {
        if (item.agent_name) {
          return item.payee+` (${item.agent_name}) `
        }
      } else {
        return item.payee
      }
    },


    getFootValue(column) {
      if (column.column =='Amount') {
        return '$'+financial(this.totalAmount, 2).toLocaleString()
      }
    },

    list_sort() {
      this.list.sort((a, b) => {
        if (this.sortBy == 'Date') {
          return  this.sortDesc?-a.mdate.diff(b.mdate):a.mdate.diff(b.mdate)
        } else  if (this.sortBy == 'Payee') {
          return  this.sortDesc?(a.payee>b.payee?1: -1): (a.payee>b.payee? -1: 1)
        }
        return 1
      })

    },

    load_all_pending_data() {
      this.$bvModal.show('LoadingProgressDiloag')
      this.currentPage = 1
      this.list        = []
      this.totalAmount = 0

      this.append_page_data(this.currentPage)
    },


    append_page_data(page_no) {

      console.log( this.current_progress, page_no, this.totalPage)

      let data = {
        page: {
          page_no   : page_no,
          page_size : this.query_page_size
        },
        query_type  : this.query_type,
        pay_type    : this.pay_type,
        status      : this.query_status,
      }

     
      getCashApi().ap_list(data).then((res) => {
        if (res.errCode == 0) {
          res.data.map(e => e.mdate = moment(e.due_date))
          this.list.push(...res.data)
          this.perPage = this.list.length
          this.current_progress = 100 * (page_no  / this.totalPage)

         
          if (page_no <= this.totalPage) {
            setTimeout(() => {
              this.append_page_data(page_no + 1)
            }, 340);
          } else {
            this.current_progress = 100
            this.$bvModal.hide('LoadingProgressDiloag')
          }
        } else {
          this.$bvModal.hide('LoadingProgressDiloag')
          //TODO error
        }

      })

    },


    export_unpaid_list () {
      let data = {
        query_type : this.query_type,
      }

      getCashReportApi().export_pm_unpaid_list(data).then(res => {
      
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        } else {
          this.$alertify.error("Failed: " + res.page.page_no);
        }
      })
    }


  },

  watch: {
    sortBy () {
      this.list_sort()
    },

    sortDesc () {
      this.list_sort()
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


              
    <b-modal centerd id="modal-pm-transfer-payment-dialog" title="Transfer" size="lg" hide-footer>
      <TransferDialog :transactions="selected_transactions" :bank_account="'PM Trust Transfer'" @cancel="$bvModal.hide('modal-pm-transfer-payment-dialog')" @confirm="onTransfered()"  />
    </b-modal>

    <b-modal centerd id="modal-pm-eft-payment-dialog" title="EFT" size="lg" hide-footer>
      <EftPaymentDialog :transactions="selected_transactions" :bank_account="'PM Trust'" @cancel="$bvModal.hide('modal-pm-eft-payment-dialog')" @confirm="onEftSetup()"  />
    </b-modal>


    <b-modal centerd id="modal-cheque-payment-dialog" title="Cheque Print" size="lg" hide-footer>
      <PrintChequeDialog :transactions="selected_transactions" :bank_account="'PM Trust'" @cancel="$bvModal.hide('modal-cheque-payment-dialog')" @confirm="onPrinted"  />
    </b-modal>

    
    <b-modal centerd id="modal-payment-eidt-dialog" title="Payment Type Edit" size="lg" hide-footer>
      <PaymentEditDialog :transaction="current_transaction"  @cancel="$bvModal.hide('modal-payment-eidt-dialog')" @confirm="onPaymentEdit"  />
    </b-modal>

    <b-modal centerd id="reverse_transaction_dialog" :title="'Reverse Transaction' "  size="lg" hide-footer>
      <div class="row mb-3">
        <div class="col-md-2">
          <label>Amount</label>
          <input type="text" class="form-control" v-model="rev_trans.amount" readonly/>
        </div>
        <div class="col-md-2">
          <label>Post Date</label>
          <flat-pickr  v-model="rev_trans.post_date" placeholder="Select a date"  class="form-control" ></flat-pickr>
        </div>
        <div class="col-md-8">
          <label>Description</label>
          <input type="text" class="form-control" v-model="rev_trans.note"/>
        </div>
      </div>
      <div class="row">
        <div  class="d-flex flex-wrap gap-2 col-md-4"></div>
        <div  class="d-flex flex-wrap gap-2 col-md-6">
          <b-button variant="danger" @click="onConfirmReverseTrans" >Confirm</b-button>
          <b-button variant="primary" @click="$bvModal.hide('reverse_transaction_dialog')" >Cancel</b-button>
        </div>
      </div>
    </b-modal>
    <!-- Reverse Transaction dialog -->

    <b-modal centerd id="LoadingProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
      <b-progress height="20px" :value="current_progress"></b-progress>
    </b-modal>


    <div class="row">
      <div class="col-lg-12">
       
        <div class="card">
          <div class="card-header">
             

            <div class="row">

              <div class="col-md-2">
                <select name="choices-multiple-remove-button" id="type_choice"  v-model="query_type" @change="onReQuery">
                  <option value="PM-LANDLORD" selected>PM Owner</option>
                  <option value="PM-EXPENSE">PM Expense</option>
                  <option value="Brokerage">Brokerage</option>
                </select>
              </div>

              <div class="col-md-1">
                <div class="row mt-2">
                  <div class="col-md-12  d-flex flex-wrap gap-2">
                    <input type="checkbox" class="form-check-control" @change="onShowEFT"/>EFT Only
                  </div>
                </div>
              </div>


              <div class="col-md-2">
                <select name="choices-multiple-remove-button" id="status_choice"  v-model="query_status" @change="onReQuery()">
                  <option value="PENDING" selected>Pending</option>
                  <option value="PROGRESS">Progress</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="CANCELLED">Cancelled</option>
                </select>

              </div>

              <div class="col-md-1" >
                <div class="row mt-2">
                  <div class="col-md-12  d-flex flex-wrap gap-2">
                    <input type="checkbox" class="form-check-control" @change="onLoadAll" :disabled="query_status == 'PENDING' ? false: true"/>Load All
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <input class="form-control" v-model="filter.SearchStr" placeholder="Search Payee"/>
              </div>

            

              <div class="col-md-4 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">
                <b-button variant="primary" :disabled="transfer_btn_disabled_flag" v-b-modal.modal-pm-transfer-payment-dialog>EFT Transfer</b-button>
                <b-button variant="primary" :disabled="eft_btn_disabled_flag" v-b-modal.modal-pm-eft-payment-dialog>EFT</b-button>
                <b-button variant="primary" :disabled="cheque_btn_disabled_flag" v-b-modal.modal-cheque-payment-dialog>Cheque</b-button>
                <b-dropdown  split  text="More"    variant="light"  >

                  <b-dropdown-item @click="export_unpaid_list">Export Unpaid Trans</b-dropdown-item>
                </b-dropdown>
              </div>
              <!-- end col -->


              

              
            </div>
            <!-- end Row-->
          
          </div>
          <div class="card-body">

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">

                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"  @filtered="onFiltered"
                  :filter-function="filterTable" striped hover 
                  class="table-check datatables" foot-clone>

                  <template #head(G)>
                    <input type="checkbox" @change="onSelectAll($event)"/>
                  </template>

                  <template #cell(G)="data">
                    <input type="checkbox" v-model="data.item.checked" @change="onSelectedTransaction($event, data.item)"/>
                   
                  </template>
                 

                  <template #cell(Payee)="data">
                    {{ get_payee_val(data.item) }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(Date)="data">
                    {{ data.item.due_date }}
                  </template>

                  <template #cell(Reference)="data">
                    <span v-if="data.item.cash_record.ref_type == 'EXPENSE' ">
                      <router-link :to="{ name: 'ap-expense-overview', query: { expense_id: `${data.item.cash_record.ref_id}` } }"
                        class="text-body fw-medium">{{ data.item.ref_id }}</router-link>
                    </span>
                    <span v-else-if="data.item.cash_record.ref_type == 'PM' ">
                      <router-link :to="{ name: 'pm-overview', query: { pm_id: `${data.item.cash_record.pm.property_id}` } }"
                        class="text-body fw-medium">{{ data.item.cash_record.pm.property_id }}</router-link>
                    </span>
                    <span v-else>
                      {{ data.item.ref_id }}
                    </span>

                  </template>


                  <template #cell(Status)="data">
                    <b-badge pill class="badge-soft-primary me-1 font-size-12" v-if="data.item.status =='PENDING'">
                      {{ 'Pending' }}
                    </b-badge>
                    <b-badge pill class="badge-soft-dark me-1 font-size-12" v-else-if="data.item.status =='COMPLETED'">
                      {{ 'Completed' }}
                    </b-badge>
                    <b-badge pill class="badge-soft-success me-1 font-size-12" v-else-if="data.item.status =='PROGRESS'">
                      {{ 'Progress' }}
                    </b-badge>
                    <b-badge pill class="badge-soft-warning me-1 font-size-12" v-else-if="data.item.status =='CANCELLED'">
                      {{ 'Cancelled' }}
                    </b-badge>

                  </template>
                  <template #cell(PayType)="data">
                    {{ data.item.type }}
                  </template>
                  <template #cell(Remark)="data">
                    {{ data.item.remarks }}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown
                      variant="white"
                      right
                      toggle-class="btn-link text-dark shadow-none"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item :disabled="data.item.status != 'PENDING'" @click="onCancelPayment(data, data.item)">Cancel Payment</b-dropdown-item>
                      <b-dropdown-item :disabled="data.item.status != 'COMPLETED'" @click="onReversePayment(data, data.item)">Reverse</b-dropdown-item>
                      <b-dropdown-item :disabled="data.item.status != 'PENDING' || query_type == 'Brokerage'" @click="editPaymentType(data, data.item)">Edit Payment Type</b-dropdown-item>
                     
                      </b-dropdown>

                  </template>



                  <template v-slot:row-details="row">
                    <div class="table align-middle table-nowrap" style="margin-left:30px;">
                      <b-table
                        :items="row.item.details"
                        :fields="[{key : 'Type'},{key : 'SubType'},{key :'Amount'}]"
                        responsive="sm"
                        :per-page="row.item.details.length"

                        class="table-check col-md-10"
                      >
                        <template  #cell(Type)="data">
                          {{ data.item.detail_category }}
                        </template>
                        <template  #cell(SubType)="data">
                          {{ data.item.detail_type }}
                        </template>
                        <template  #cell(Amount)="data">
                          ${{ data.item.detail_amount.toLocaleString() }}
                        </template>

                       
                      </b-table>
                    </div>
                  </template>


                  <template #foot()="data">
                    <i>{{ getFootValue(data) }}</i>
                  </template>


                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
